@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700&family=Roboto:wght@400;700&display=swap');

:root {
  --light-primary: #FAFAFA;
  --light-secondary: #a8b0cb;
  --light-thirdary: #697087;
  --dark-primary: #101829;
  --dark-secondary: #1E1E1E;
  --pink: #A43560;
}

* {
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: var(--light-primary);
  margin: 0;
  color: var(--light-thirdary);
  font-family: 'Libre Franklin', serif;
}

a {
  cursor: pointer;
}

// .light {
//   background-color: var(--light-primary);
// }

// .dark {
//   background-color: var(--dark-primary);
// }

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects, .about, .skills, .contact {
  width: 100%;
  padding: 20px;
}

h1, h2 {
  color: var(--light-thirdary);
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
  display: inline-block;
  border-bottom: 4px solid var(--pink);
  margin-top: 0;
  padding-bottom: 8px;
}

p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
}

a {
  color: #697087;
  transition: 0.3s;
  cursor: pointer;
}

a:hover {
  color: var(--pink);
}

@media (min-width: 720px) {

  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 40px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (min-width: 965px) {
  .projects, .about, .skills, .contact{
    padding: 50px 100px;
  }
}

@media (min-width: 1440px) {
  .projects, .about, .skills, .contact {
    max-width: 85%;
  }
}
