.topBtn {
  cursor: pointer;
  border: none;
  position: fixed;
  display: flex;
  background-color: var(--light-thirdary);
  padding: 8px;
  border-radius: 50%;
  bottom: 90px;
  right: 20px;
  z-index: 8;
  color: var(--light-primary);
  font-size: 20px;
}

.topBtn:hover {
  color: var(--light-primary);
  background-color: var(--pink);
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}


@media (min-width: 568px) {
  .topBtn {
    font-size: 32px;
    bottom: 100px;
    right: 50px;
  }
}
