.about {
  margin-top: 40px;
}

.about-p-container {
  display: flex;
  flex-direction: column;

  .about-intro {
    font-size: 30px;
    color: var(--light-secondary);
    font-weight: 700;
    margin-bottom: 0;
    line-height: 38px;
  }

  .about-contact-link {
    cursor: pointer;
    border-bottom: 2px solid var(--pink);

    &:hover {
        color: var(--pink);
    }
  }
}

@media (min-width: 720px) {
  .about-p-container {
    flex-direction: row;
  }
   .about-intro {
     margin: auto;
   }

   .about-main-text {
     max-width: 60%;
   }
}



.about p {
  margin: 32px 16px;
}
