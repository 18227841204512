.theme-button {
  font-size: large;
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--light-thirdary);
  transition: 0.3s;
}

.theme-button:hover {
  color: var(--pink);
}

.theme-btn-desktop {
  font-size: 20px;
}

.navbar {
  padding: 0 16px;
  position: fixed;
  top: 0;
  z-index: 9;
  height: 50px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-dark {
  background-color: var(--dark-primary);
}

.navbar-light {
  background-color: var(--light-primary);
}

.logo {
  height: 50px;
  width: 50px;
}

li {
  text-transform: uppercase;
}

.desktop-list {
  display: none;
}

.mobile-hamburger {
  font-size: x-large;
  margin-right: 12px;
  margin-top: 8px;
  cursor: pointer;
  .dark {
    color: var(--light-primary);
  }
}

.theme-btn-mobile {
  font-size: "36px";
}

.mobile-hamburger:hover {
  color: var(--pink);
}

.mobile-list {
  display: none;
}

.mobile-list-active {
  font-weight: 700;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 -16px;
  padding: 0;
  list-style: none;
  height: 100vh;
  width: 100vw;
  top: 50px;
}

.mobile-list-active-dark {
  background-color: var(--dark-primary);
}

.mobile-list-active-light {
  background-color: var(--light-primary);
}

.mobile-list-active li {
  font-size: 36px;
  margin-bottom: 36px;
}

.desktop-list li {
  margin-left: 16px;
}

.desktop-list {
  margin: 0;
  display: flex;
  margin-right: 24px;
  list-style: none;
  display: none;
}

@media (min-width: 568px) {
  .desktop-list {
    display: inline-flex;
    align-items: center;
  }

  .mobile-hamburger, .mobile-list {
    display: none;
  }

  .mobile-list-active {
    display: none;
  }
}
