footer {
    padding: 0 16px;
}

.footer-container {
    border-top: 2px solid var(--pink);
    display: flex;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
}

footer p {
    display: none;
}

.contactIcon {
    fill: var(--light-thirdary);
    transition: 0.3s;
}

.contactIcon:hover {
    fill: var(--pink);}

footer ul {
    padding: 0;
    font-size: large;
    display: flex;
    justify-content: center;
    gap: 10px;
    list-style-type: none;
}

@media (min-width: 520px) {
    .footer-container {
        justify-content: space-between;
    }

    footer p {
        display: inline-block;
        font-size: small;
    }
}

@media (min-width: 965px) {
    .footer-container {
        width: 100%;
    }
}
