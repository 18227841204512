.banner {
  padding: 40px 30px;
  height: 100vh;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 20px auto;

  h1 {
    margin: 0;
    font-size: 28px;
  }

  .light-text {
    padding: 0;
    margin: 0;
    border: none;
    color: var(--light-secondary);
  }
}

.banner-pink {
  color: var(--pink);
  margin-bottom: 0;
}

.banner-photo img {
  width: 200px;
  height: auto;
  border-radius: 50%;
  box-shadow: 3px 3px 23px 3px rgba(65, 63, 63, 0.3);
}

.banner-btn {
  display: none;
  padding: 10px 15px;
  border: 1px solid var(--light-secondary);
  font-size: 20px;
  color: var(--light-secondary);
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s;
  align-items: center;
}

.banner-btn:hover {
  color: var(--light-primary);
  border-color: var(--pink);
  background-color: var(--pink);
}

.banner-btn:hover .btn-arrow {
  transform: rotate(90deg);
}

.btn-arrow {
  margin-left: 10px;
  transition: transform 0.3s;
}

.banner-p-btn {
  font-size: 14px;
  margin-bottom: 40px;
}

@media (max-height: 640px) {
  .banner {
    margin-bottom: 60px;
  }
}

@media (min-width: 568px) {
  .banner-btn{
    display: inline-flex;
  }

  .banner-photo img {
    width: 280px;
    height: auto;
  }
}

@media (min-width: 720px) {
  .banner {
    a {
      visibility: visible;
    }
  
    h1 {
      font-size: 52px;
    }
  }

  .banner-photo img {
    width: 320px;
    height: auto;
  }

  .banner-p-btn {
    font-size: 16px;
  }
}

@media (min-width: 965px) {
  .banner-photo img {
    width: 360px;
    height: auto;
  }

  .banner {
    flex-direction: row-reverse;
    padding: 0 100px;
  }

  .banner-intro {
    padding-left: 0;
    margin-right: 50px;
  }
}
