.contact {
  padding-bottom: 50px;
}

form {
  display: flex;
  max-width: 600px;
  flex-direction: column;
  gap: 20px;
  margin: 32px auto;
}

input[type=text], input[type=email], textarea {
  padding: 8px 8px;
}

.btn {
  padding: 10px 15px;
  margin: 0 auto;
  border: 1px solid var(--light-secondary);
  font-size: 20px;
  color: var(--light-secondary);
  cursor: pointer;
  display: inline-flex;
  background-color: var(--light-primary);
  transition: color 0.3s;
}

.btn-dark {
  background-color: var(--dark-primary);
}

.btn:hover {
  color: var(--light-primary);
  border-color: var(--pink);
  background-color: var(--pink);
}

textarea {
  font-family: inherit;
  // font-size: inherit;
}

// @media (min-width: 720px) {
//   // form {
//   //   padding: 32px 50px 50px 50px;
//   // }
// }

// @media (min-width: 965px) {
//   // form {
//   //   padding: 32px 100px 50px 100px;
//   // }
// }
