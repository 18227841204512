.card {
  width: 100%;
  max-height: 350px;
  background-color: var(--dark-secondary);
  position: relative;
  border-radius: 10px;
  box-shadow: 3px 3px 23px 3px rgba(65, 63, 63, 0.3);

  h3 {
    padding-left: 16px;
  }
}

.card-preview {
  height: 80%;
  position: relative;
  &:hover {
    .card-languages {
      opacity: 1;
    }
  }
}

img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.card-languages {
  position: absolute;
  background-color: rgba(#1D1D1D, 0.9);
  border-radius: 10px 10px 0 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 16px;
  opacity: 0;
  transition: 0.5s;

  a {
    text-decoration: none;
  }

  .card-language {
    transition: 0.1s;
    font-size: 40px;
    margin-left: 8px;
  }

  .card-language:hover {
    font-size: 44px;
  }

  .next-logo {
    font-size: 65px;
  }

  .next-logo:hover {
    font-size: 85px;
  }
}


.card-info {
  height: 20%;
  color: var(--light-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    cursor: pointer;
    font-size: 32px;
    padding-right: 16px;
    text-decoration: none;
  }
}

.card-link {
  padding-top: 5px;
  display: flex;
  align-items: center;
}


@media (min-width: 568px) {
  .card {
    width: 45%;

    h3 {
      font-size: 16px;
    }
  }

  .card-info {
    padding: 0 8px;

    a {
      font-size: 30px;
    }
  }
}

@media (min-width: 720px) {
  .card {
    width: 45%;

    h3 {
      font-size: 22px;
    }

    .card-language {
      font-size: 52px;
    }

    .card-language:hover {
      font-size: 64px;
    }

    .next-logo {
      font-size: 85px;
    }

    .next-logo:hover {
      font-size: 100px;
    }
  }
}
