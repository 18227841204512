.skills {
  margin: 32px 0;

  img {
    width: 60px;
  }
}

.skills-language {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
  a {
    text-decoration: none;
    font-size: 60px;
  }
}

@media (min-width: 568px) {
  .skills {
    img {
      width: 80px;
    }
  }

  .skills-language {
    a {
        font-size: 80px;
      }
  }
}

@media (min-width: 769px) {
  .skills {
    img {
      width: 100px;
    }
  }

  .skills-language {
    max-width: 80%;
      a {
        font-size: 100px;
      }
    }
}
